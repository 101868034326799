/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto');
body {
    font-family: 'Roboto';
    color: var(--text-color);
    margin: 0;
    --header-color: #D1DFB7;
    --sidebar-color: #FAE6BE;
    --text-color: black;
}
 